import animateScrollTo from 'animated-scroll-to';

function throttle(func, ms) {

    let isThrottled = false;
    let timeout;

    function wrapper() {
        if (!isThrottled) {
            func(...arguments);
        }
        isThrottled = true;

        clearTimeout(timeout);
        timeout = setTimeout(() => isThrottled = false, ms);
    }

    return wrapper;
}

export const BlockScroller = (() => {
    const blockNameAttribute = 'data-block-name';
    const blocksList = document.querySelectorAll('.js-scroll-block');
    const onScrollListeners = [];
    const scrollSpeed = 800;
    const scrollTitle = document.querySelector('.js-scroll-title');

    let scrollInProgress = false;
    let activeBlock = null;

    const _getBlockByName = (name) => {
        return Array.from(blocksList).find(block => block.getAttribute(blockNameAttribute) === name);
    };

    const _scrollToBlock = (block) => {
        scrollInProgress = true;
        animateScrollTo(block.offsetTop, {
            speed: scrollSpeed,
            cancelOnUserAction: false,
            easing: (t) => (--t) * t * t + 1,
        }).then(() => {
            requestAnimationFrame(() => scrollInProgress = false);
        });
    };

    const _emitOnScroll = (block) => {
        const onScrollEvent = {
            index: Array.from(blocksList).findIndex(blockInList => blockInList === block),
            name: block.getAttribute(blockNameAttribute)
        }

        onScrollListeners.forEach(callback => callback(onScrollEvent));
    }

    const _defineActiveBlock = () => {
        const targetBlock = Array.from(blocksList).find(block => {
            const blockRect = block.getBoundingClientRect();
            const windowMiddlePoint = window.innerHeight / 2;

            if (blockRect.y < windowMiddlePoint && (blockRect.y + blockRect.height) > windowMiddlePoint) {
                return block;
            }
        });

        if (targetBlock && activeBlock !== targetBlock) {
            activeBlock = targetBlock;
            _emitOnScroll(activeBlock);

            if (activeBlock.getAttribute(blockNameAttribute) === 'contacts') {
                scrollTitle.innerHTML= "Scroll Up";
            } else {
                scrollTitle.innerHTML= "Scroll Down";
            }
        }
    };

    const scrollTo = (name) => {
        _scrollToBlock(_getBlockByName(name));
    };

    const scrollToIndex = (index) => {
        _scrollToBlock(blocksList[index]);
    };

    const onScroll = (callback) => {
        onScrollListeners.push(callback);
    }

    requestAnimationFrame(() => {
        _defineActiveBlock();

        const wheelHandler = throttle(event => {
            if (!scrollInProgress) {
                if (event.deltaY > 0) {
                    const nextBlockIndex = Array.from(blocksList).findIndex(blockInList => blockInList === activeBlock) + 1;

                    if (blocksList[nextBlockIndex]) {
                        _scrollToBlock(blocksList[nextBlockIndex]);
                    }
                } else {
                    const prevBlockIndex = Array.from(blocksList).findIndex(blockInList => blockInList === activeBlock) - 1;

                    if (blocksList[prevBlockIndex]) {
                        _scrollToBlock(blocksList[prevBlockIndex]);
                    }
                }
            }
        }, 50);

        window.addEventListener('wheel', event => {
            if (Math.abs(event.deltaY) > 4) {
                wheelHandler(event);
            }

            event.preventDefault();
            event.stopPropagation();

            return false;
        }, {passive: false});

        window.addEventListener('scroll', () => {
            _defineActiveBlock();
        }, {capture: false, passive: true})
    });

    return {
        scrollTo,
        scrollToIndex,
        onScroll
    }
})();

window.BlockScroller = BlockScroller;