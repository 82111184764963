const MainNavigation = (() => {
    const navigation =document.querySelector('.js-main-nav');
    const navigationItems =document.querySelector('.js-main-nav-item');

    const _clearActive = () => {
        navigation.querySelector('.js-main-nav-item.active')?.classList?.remove('active');
    }

    const _setActive = (name) => {
        navigation.querySelector(`.js-main-nav-item[data-name="${name}"]`)?.classList.add('active');
    }

    BlockScroller.onScroll(event => {
        _clearActive();
        _setActive(event.name);
    });

    return {};
})();