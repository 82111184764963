const DropMenu = (() => {
  const dropButton = document.querySelector("#dropButton");
  const dropContent = document.querySelector("#dropContent");
  const menu = document.querySelector(".header__left");
  const logo = document.querySelector(".logo");
  const menuList = document.querySelector(".menu__list");
  const menuListLink = document.querySelectorAll(".menu__list-link");

  const toggle = () => {
      dropContent.classList.toggle("show");
      menu.classList.toggle("active");
      dropButton.classList.toggle("close");
      logo.classList.toggle("hidden");
      menuList.classList.toggle("show");
  }

  dropButton.addEventListener('click', toggle);

  menuListLink.forEach(link => {
      link.addEventListener("click", toggle);
  });

  return {
      toggle
  }
})();
