const viewer = OpenSeadragon({
    id: "openseadragon",
    prefixUrl: "https://cdnjs.cloudflare.com/ajax/libs/openseadragon/4.1.0/images/",
    navigatorSizeRatio: 0.25,
    wrapHorizontal: false,
    tileSources: {
        type: "image",
        url: require("../../images/map.svg"),
    },
    minZoomLevel: "1",
    maxZoomLevel: "3",
    useCanvas: false,
    showNavigationControl: false,
    showZoomControl: false,
    maxZoomPixelRatio: "100",
});
