const Pagination = (() => {
    const pagination = document.querySelector('.js-pagination');
    const paginationItems = pagination.querySelectorAll('.js-pagination-item');

    const _clearActive = () => {
        pagination.querySelector('.js-pagination-item.active')?.classList.remove('active');
    };

    const _setActive = (index) => {
        Array.from(paginationItems)[index].classList.add('active');
    };

    paginationItems.forEach((paginationItem, index) =>
        paginationItem.addEventListener('click', () => {
            BlockScroller.scrollToIndex(index);
    }));

    BlockScroller.onScroll(event => {
        _clearActive();
        _setActive(event.index);
    });

    return {};
})();