const TabSet = (() => {
    document.querySelectorAll('.js-tab-set').forEach(tabSet => {
        const contentList = tabSet.querySelectorAll('.js-tab-content');
        const controlList = tabSet.querySelectorAll('.js-tab-control');
        const labelList = tabSet.querySelectorAll('.js-tab-label');
        const logoList = tabSet.querySelectorAll('.js-tab-logo');
        const brandList = document.querySelector('.brands-switcher')

        controlList.forEach((control, index) => {

            control.addEventListener('change', event => {
                const targetContentName = event.currentTarget.getAttribute('data-tab-target');

                contentList.forEach(contentEl => contentEl.style.display = "none");
                labelList.forEach(label => label.classList.remove('active'));
                logoList.forEach(logo => logo.classList.remove('active'));

                if (event.currentTarget.checked) {
                    const targetContent = tabSet.querySelector(`.js-tab-content[data-tab-name="${targetContentName}"]`);
                    targetContent.style.display = "grid";
                    const targetLabel = tabSet.querySelector(`.js-tab-label[data-tab-label="${targetContentName}"]`);
                    targetLabel.classList.add('active');
                    const targetLogo = tabSet.querySelector(`.js-tab-logo[data-tab-logo="${targetContentName}"]`);
                    targetLogo.classList.add('active');
                }
                const positions = [0, 80, 120];
                let newPosition = 0;

                if (screen.width < screen.height) {
                    if (controlList[index].checked) {
                        newPosition = positions[index];
                    }
                    brandList.scrollTo(Number(newPosition), 0)
                }

            });
        });

        contentList.forEach(contentEl => contentEl.style.display = "none");

        const firstTabContentName = controlList[0].getAttribute('data-tab-target');
        const firstTabContent = tabSet.querySelector(`.js-tab-content[data-tab-name="${firstTabContentName}"]`);

        firstTabContent.style.display = 'grid';
        controlList[0].checked = true;

    });

    return null;
})();
