import Toastify from 'toastify-js';

export const ContactForm = (() => {
    const formUrl = 'https://formspree.io/f/xnqykbzw';
    let form;
    let button;

    const _init = () => {
        form = document.querySelector('.js-contact-form');
        button = form.querySelector('.js-contact-form-button');

        form.addEventListener('submit', event => {
            event.preventDefault();

            _sendForm(new FormData(form))
        });
    }

    const _setButtonDisabled = (isDisabled) => {
        isDisabled
            ? button.setAttribute('disabled', isDisabled)
            : button.removeAttribute('disabled', isDisabled);
    }


    const _sendForm = (data) => {
        _setButtonDisabled(true);
        fetch(formUrl, {
            method: 'POST',
            body: data,
            redirect: "manual"
        }).then(() => {
            Toastify({
                text: "Your message successfully sent!",
                duration: 3000,
                className: 'toast-success',
            }).showToast();

            form.reset();
        }).catch(() => {
            Toastify({
                text: "Something went wrong!",
                duration: 3000,
                className: 'toast-error',
            }).showToast();
        }).finally(() => {
            _setButtonDisabled(false);
        });
    }

    document.addEventListener('DOMContentLoaded', () => _init());

    return {};
})();

window.ContactForm = ContactForm;